import RouteURL from '~routes'

export const BOTTOM_NAV = [
  {
    heading: 'Asset Hub',
    items: [
      { text: 'Token staking', href: RouteURL.ASSET_HUB },
      { text: 'NFT staking', href: RouteURL.NFT_STAKING },
      { text: 'NFT staked', href: RouteURL.NFT_STAKED },
    ],
  },
  {
    heading: 'Swap',
    items: [
      { text: 'Trade', href: '/' },
      { text: 'Liquidity', href: '/' },
      { text: 'Launchpad', href: '/' },
    ],
  },
  {
    heading: 'Marketcap',
    items: [
      { text: 'Trending athletes', href: '/' },
      { text: 'All athletes', href: '/' },
    ],
  },
  {
    heading: 'NFT Marketplace',
    items: [
      { text: 'Recently added athletes', href: '/' },
      { text: 'Live NFT listing', href: '/' },
      { text: 'Teams', href: '/' },
    ],
  },
]

export const LINKS = [
  {
    name: 'Terms & Conditions',
    link: '#',
  },
  {
    name: 'Privacy Policy',
    link: '#',
  },
  {
    name: 'Whitepaper',
    link: '/whitepaper.pdf',
  },
  {
    name: 'Lightpaper',
    link: '/lightpaper.pdf',
  },
]
