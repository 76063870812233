// extracted by mini-css-extract-plugin
export var cardVideo = "CardVideo_cardVideo__j0w2K";
export var hero = "CardVideo_hero__ylFVU";
export var videoWrapperOuter = "CardVideo_videoWrapperOuter__DffEz";
export var videoWrapperInner = "CardVideo_videoWrapperInner__tcLYz";
export var glowVisible = "CardVideo_glowVisible__LlvKM";
export var playerRoot = "CardVideo_playerRoot__bmg1k";
export var cardFooter = "CardVideo_cardFooter__pl0m5";
export var ytLink = "CardVideo_ytLink__oV6TM";
export var rotate = "CardVideo_rotate__W-oXa";
export var dotty = "CardVideo_dotty__3TqH4";