import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { round } from 'lodash'
import SEO from '~components/SEO'
import { MOCK_NFTS_12 } from '~utils/mocks'
import { useMediaQuery } from 'react-responsive'

import ModalConfirmStaking from '../ModalConfirmStaking'
import CardInfo from '../CardInfo'

import CardNFTStaked from './components/CardNFTStaked'
import ButtonClaim from './components/ButtonClaim'

import * as style from './BlockNftStaked.module.scss'

const BlockNftStaked = (props) => {
  const { className, ...rest } = props

  const isXxl = useMediaQuery({ query: '(min-width: 1440px)' })

  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false)
  const showModalConfirm = () => setIsModalConfirmVisible(true)
  const hideModalConfirm = () => setIsModalConfirmVisible(false)

  const mockNfts = [
    { ...MOCK_NFTS_12[0], claimAmount: 348.07 },
    ...MOCK_NFTS_12.slice(1),
  ]

  return (
    <>
      <SEO title="Staked NFTs" />

      <ModalConfirmStaking
        title="Confirm unstaking"
        show={isModalConfirmVisible}
        onHide={hideModalConfirm}
        onConfirm={hideModalConfirm}
        nftCount={mockNfts.length}
        data={[
          {
            label: 'Your rewards',
            value: '23.434 BLOCK',
          },
        ]}
      />

      <div {...rest} className={classNames(style.blockNftStaked, className)}>
        <CardInfo
          data={[
            {
              label: 'BLOCK BALANCE',
              value: `45113.441`,
              variant: 'balance',
              iconName: 'icon-blockasset-logo',
              iconProps: {
                size: [31, 35],
                isFromInlineDefs: true,
              },
            },
            {
              label: 'ASSET BALANCE',
              value: '2344.2334',
              variant: 'balance',
              colorVariant: 'orange',
              iconName: 'icon-logo-asset',
              iconProps: {
                size: [31, 35],
                isFromInlineDefs: true,
              },
            },
          ]}
          variant="balance"
        />

        <CardInfo
          data={[
            {
              label: 'NFT STAKED',
              value: '48',
              colorVariant: 'orange',
              iconName: 'layers',
            },
            {
              label: 'TOKEN FOR CLIMED',
              value: `${isXxl ? '4562.34590' : round(4562.3459, 2)} ASSET`,
              colorVariant: 'orange',
              iconName: 'chevrons-right',
            },
          ]}
          variant="dark"
          button={<ButtonClaim isXxl={isXxl} />}
          isGridArea
        />

        <div className="grid-asset-hub-nfts">
          {mockNfts.map((nft) => (
            <CardNFTStaked key={nft.id} {...nft} onUnstake={showModalConfirm} />
          ))}
        </div>
      </div>
    </>
  )
}

BlockNftStaked.defaultProps = {
  className: '',
}

BlockNftStaked.propTypes = {
  className: PropTypes.string,
}

export default BlockNftStaked
