import React, { useContext } from 'react'
import { Container, Button } from 'react-bootstrap'

import BlockassetLogo from '~components/BlockassetLogo'
import OverlayContext from '~contexts/OverlayContext'
import useAuth from '~hooks/useAuth'

import HeaderNavMenu from './components/HeaderNavMenu'
import DropdownWallet from './components/DropdownWallet'

import * as style from './Header.module.scss'

const Header = () => {
  const { isLoggedIn } = useAuth()

  const { showModalConnectWallet } = useContext(OverlayContext)

  return (
    <header className={style.headerWrapper}>
      <Container className={style.header}>
        <BlockassetLogo variant="header" />
        <HeaderNavMenu className={style.navMenu} />
        <div className={style.gridHeaderRight}>
          <div className={style.btnWrapper}>
            {isLoggedIn ? (
              <DropdownWallet />
            ) : (
              <Button
                title="Connect wallet"
                variant="neon"
                onClick={showModalConnectWallet}
              >
                Connect Wallet
              </Button>
            )}
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
