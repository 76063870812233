import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Dropdown } from 'react-bootstrap'
import copy from 'copy-to-clipboard'

import Icon from '~components/Icon'
import useAuth from '~hooks/useAuth'

import BlockTokenPrice from '../BlockTokenPrice'
import BlockBalance from '../BlockBalance'
import { DROPDOWN_WALLET_ITEMS } from './constants'
import * as style from './DropdownWallet.module.scss'

const DropdownWallet = (props) => {
  const { className, ...rest } = props

  const { disconnect } = useAuth()

  const WALLET_ADDRESS = '0xWALLET'

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = (fullValue) => {
    copy(fullValue, { debug: true })

    if (isCopied) return

    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  const handleSelect = (eventKey) => {
    switch (eventKey) {
      case 'copy':
        handleCopy(WALLET_ADDRESS)
        break
      case 'logout':
        disconnect()
        setIsDropdownOpen(false)
        break
      default:
        setIsDropdownOpen(false)
    }
  }

  return (
    <Dropdown
      {...rest}
      onSelect={handleSelect}
      className={className}
      autoClose="outside"
      show={isDropdownOpen}
      onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
    >
      <Dropdown.Toggle as="button" className={style.dropdownToggle}>
        <div className={style.toggleInner}>
          <BlockTokenPrice variant="dropdown" isDropdownOpen={isDropdownOpen} />
          <div className={style.overlay} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        variant="dark"
        className={classNames('text-syne-16-semi-bold', style.dropdownMenu)}
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
        }}
      >
        <hr />
        <BlockBalance balance={231} className={style.blockBalance} />
        {DROPDOWN_WALLET_ITEMS.map(({ key, text, icon, active }) => {
          const isActive = key === 'copy' && isCopied

          return (
            <Dropdown.Item
              as="button"
              className={classNames({
                'is-copied': isActive,
              })}
              key={key}
              eventKey={key}
            >
              <Icon name={isActive ? active?.icon : icon} size={24} />
              <span>{isActive ? active?.text : text}</span>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

DropdownWallet.defaultProps = {
  className: '',
}

DropdownWallet.propTypes = {
  className: PropTypes.string,
}

export default DropdownWallet
