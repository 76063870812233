export const MOCK_NFTS = [
  {
    id: 1,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Wayne',
      lastName: 'Rooney',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 5751,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
  },
  {
    id: 2,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Muhammad',
      lastName: 'Ali',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 8180,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
  },
  {
    id: 3,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Alexander',
      lastName: 'Ovechkin',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 6370,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
  },
  {
    id: 4,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Michael',
      lastName: 'Bisping',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 2485,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
  },
]

export const MOCK_NFTS_12 = Array(3)
  .fill(MOCK_NFTS)
  .flat()
  .map((item, index) => ({ ...item, id: index + 1 }))
