import urlRegex from 'url-regex'
import { trim } from 'lodash'

export const insertMiddleEllipsis = (
  text,
  containerWidth,
  charWidth = 11,
  ellipsis = '...',
  safetyMargin = 0
) => {
  const textLen = text.length
  const charsFit = Math.floor(containerWidth / charWidth)
  const charsToTrim = Math.max(0, textLen - charsFit + safetyMargin)

  if (!charsToTrim) return text

  const charsToTrimWEllipsis = charsToTrim + 1 + safetyMargin

  const part1 = text.substr(0, Math.floor(textLen / 2))
  const part2 = text.substr(Math.floor(textLen / 2))

  const part1Trimmed = part1.substr(
    0,
    part1.length - Math.floor(charsToTrimWEllipsis / 2)
  )
  const part2Trimmed = part2.substr(Math.ceil(charsToTrimWEllipsis / 2))

  return `${part1Trimmed}${ellipsis}${part2Trimmed}`
}

export const maskNumberValue = (value) =>
  value
    .replace(',', '.') // replace `,` with `.`
    .replace(/^\./gm, '0.') // replace `.` with `0.` at the line start
    .replace(/(.*[.].*)([.])/gm, '$1') // `prevent 2 dots (e.g. `123.45.6`)
    .replace(/[^\d.]/gi, '') // allow digits and dots only

export const limitMaxValue = (value, max) =>
  parseFloat(value) > max ? max : value

export const normalizeUrl = (url) => trim(url, '/') || '/'

export const RHF_URL_RULE = {
  pattern: {
    value: urlRegex({ strict: false }),
    message: 'Please, enter a valid url',
  },
}

export const RHF_EMAIL_RULE = {
  pattern: {
    value:
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Please, enter a valid email address',
  },
}
