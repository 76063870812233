import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Dropdown } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

import { TOP_NAV_ITEMS } from '~utils/constants'
import useAuth from '~hooks/useAuth'
import { normalizeUrl } from '~utils'
import Icon from '~components/Icon'

import * as style from './NavSelect.module.scss'

const NavSelect = (props) => {
  const { className, ...rest } = props

  const { pathname } = useLocation()

  const { isLoggedIn } = useAuth()

  const { subItems: subItemsSrc } = TOP_NAV_ITEMS[0]

  const subItems = subItemsSrc.filter(
    (subItem) => normalizeUrl(subItem.href) !== normalizeUrl(pathname)
  )

  const activeItem = subItemsSrc.find(
    (subItem) => normalizeUrl(subItem.href) === normalizeUrl(pathname)
  )

  return (
    <Dropdown {...rest} className={classNames(style.navSelect, className)}>
      <Dropdown.Toggle
        as="button"
        className={classNames('text-syne-14-bold', 'dropdown-toggle-rainbow')}
      >
        <span>{activeItem?.text}</span>
        <Icon name="icon-chevron-down-wallet" size={12} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        variant="rainbow"
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
        }}
      >
        {subItems.map(({ text, href, requiresLogIn, group }, index) => (
          <React.Fragment key={text}>
            <Dropdown.Item
              as={Link}
              to={href}
              disabled={requiresLogIn && !isLoggedIn}
            >
              {text}
            </Dropdown.Item>
            {group !== (subItems?.[index + 1]?.group ?? group) && (
              <hr className="hr-card" />
            )}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

NavSelect.defaultProps = {
  className: '',
}

NavSelect.propTypes = {
  className: PropTypes.string,
}

export default NavSelect
