// extracted by mini-css-extract-plugin
export var footer = "style_footer__2IByV";
export var footerInner = "style_footer_inner__NVN0Z";
export var footerTop = "style_footerTop__DV6De";
export var topNavLinksList = "style_topNavLinksList__g13H6";
export var blockFooterBox = "style_blockFooterBox__83pDg";
export var blockLogo = "style_blockLogo__Tcz3i";
export var navHeading = "style_navHeading__9fXae";
export var footerBottom = "style_footerBottom__-4FlZ";
export var bottomLinks = "style_bottomLinks__raF7C";
export var bottomLinksItem = "style_bottomLinks_item__iY5+B";
export var bottomLinksLink = "style_bottomLinks_link__+TdMX";
export var rotate = "style_rotate__6gi+V";
export var dotty = "style_dotty__hnIJG";