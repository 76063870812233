import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Navigate } from 'react-router-dom'

import RouteURL from '~routes'

import CardSideNav from './components/CardSideNav'
import BlockTokenStaking from './components/BlockTokenStaking'
import BlockNftStaking from './components/BlockNftStaking'
import * as style from './AssetHub.module.scss'
import BlockNftStaked from './components/BlockNftStaked'

const AssetHub = (props) => {
  const { className, ...rest } = props

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  return (
    <div className={style.pageWrapper}>
      <Container>
        <div {...rest} className={classNames(style.assetHub, className)}>
          <aside>
            <CardSideNav />
          </aside>
          <Routes>
            <Route
              index
              element={<BlockTokenStaking isDesktop={isDesktop} />}
            />
            <Route
              path={RouteURL.NFT_STAKING}
              exact
              element={<BlockNftStaking />}
            />
            <Route
              path={RouteURL.NFT_STAKED}
              exact
              element={<BlockNftStaked />}
            />
            <Route
              path="*"
              element={<Navigate to={RouteURL.ASSET_HUB} replace />}
            />
          </Routes>
        </div>
      </Container>
    </div>
  )
}

AssetHub.defaultProps = {
  className: '',
}

AssetHub.propTypes = {
  className: PropTypes.string,
}

export default AssetHub
