import dayjs from 'dayjs'
import { shuffle } from 'lodash'

const MEDIA_PAGE_HERO_VIDEOS_SRC = [
  {
    id: 1,
    title: 'NFT NYC: TIMES SQUARE | LEGENDS UNVEILING WITH MIKE BISPING',
    videoUrl: 'https://www.youtube.com/watch?v=kv-Fiq9l06s',
    timestamp: dayjs().subtract(0, 'd').subtract(84, 'm').unix(),
    // no maxres thumbnail
    thumbnailUrl: `http://i3.ytimg.com/vi/kv-Fiq9l06s/sddefault.jpg`,
  },
  {
    id: 2,
    title: 'BLOCKASSET | THE ATHLETE VERIFIED ECOSYSTEM',
    videoUrl: 'https://www.youtube.com/watch?v=jYHGK-8G-aE',
    timestamp: dayjs().subtract(2, 'd').subtract(60, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/jYHGK-8G-aE/maxresdefault.jpg',
  },
  {
    id: 3,
    title: 'DOSBRAK | LEGENDS NFT ARTIST',
    videoUrl: 'https://www.youtube.com/watch?v=kTl4fGNMeLg',
    timestamp: dayjs().subtract(4, 'd').subtract(888, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/kTl4fGNMeLg/maxresdefault.jpg',
  },
  {
    id: 4,
    title: 'BLOCKASSET STUDIOS | FAN GOVERNED MEDIA',
    videoUrl: 'https://www.youtube.com/watch?v=YBxXyHkStNk',
    timestamp: dayjs().subtract(6, 'd').subtract(2, 'm').unix(),
    // no maxres thumbnail
    thumbnailUrl: `http://i3.ytimg.com/vi/YBxXyHkStNk/sddefault.jpg`,
  },
  {
    id: 5,
    title: 'ALEX OVECHKIN INTRODUCED BLOCKASSET LEGENDS COLLECTION',
    videoUrl: 'https://www.youtube.com/watch?v=l94s2yBSZro',
    timestamp: dayjs().subtract(6, 'd').subtract(1688, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/l94s2yBSZro/maxresdefault.jpg',
  },
  {
    id: 6,
    title: "LONDON'S BANKSEY TUNNEL | LEGENDS COLLECTION",
    videoUrl: 'https://www.youtube.com/watch?v=4ADVdxqb3pc',
    timestamp: dayjs().subtract(7, 'd').subtract(999, 'm').unix(),
    // no maxres thumbnail
    thumbnailUrl: `http://i3.ytimg.com/vi/4ADVdxqb3pc/sddefault.jpg`,
  },
  {
    id: 7,
    title: '2021 BLOCKASSET RECAP',
    videoUrl: 'https://www.youtube.com/watch?v=vpynvUxIAEU',
    timestamp: dayjs().subtract(7, 'd').subtract(1900, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/vpynvUxIAEU/maxresdefault.jpg',
  },
  {
    id: 8,
    title: 'BLOCKACCESS: MVP EP1 PAGE WINS ALI LEGEND NFT',
    videoUrl: 'https://www.youtube.com/watch?v=aGsCJBfMJEo',
    timestamp: dayjs().subtract(8, 'd').subtract(88, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/aGsCJBfMJEo/maxresdefault.jpg',
  },
  {
    id: 9,
    title: 'MUHAMMAD ALI NFT COLLECTION ANNOUNCMENT',
    videoUrl: 'https://www.youtube.com/watch?v=pWvOOvxX1X8',
    timestamp: dayjs().subtract(9, 'd').subtract(720, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/pWvOOvxX1X8/maxresdefault.jpg',
  },
  {
    id: 10,
    title: 'BLOCKACCESS: DARREN TILL TRAILER',
    videoUrl: 'https://www.youtube.com/watch?v=YXDi1IkNQQw',
    timestamp: dayjs().subtract(9, 'd').subtract(1888, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/YXDi1IkNQQw/maxresdefault.jpg',
  },
  {
    id: 11,
    title: 'BLOCKACCESS: MICHAEL VENOM PAGE TRAILER',
    videoUrl: 'https://www.youtube.com/watch?v=RGOiKijPIDs',
    timestamp: dayjs().subtract(10, 'd').subtract(8, 'm').unix(),
    thumbnailUrl: 'https://i3.ytimg.com/vi/RGOiKijPIDs/maxresdefault.jpg',
  },
]

export const MEDIA_PAGE_HERO_VIDEOS = shuffle(MEDIA_PAGE_HERO_VIDEOS_SRC)

export const MEDIA_PAGE_VIDEO_CARDS = MEDIA_PAGE_HERO_VIDEOS
