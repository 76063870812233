import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { MEDIA_FILTER_TABS } from './constants'
import MediaFilterTab from '../MediaFilterTab'

import * as style from './MediaFilterBlock.module.scss'

const MediaFilterBlock = forwardRef((props, ref) => {
  const { className, activeMediaFilter, setActiveMediaFilter, ...rest } = props

  return (
    <div
      {...rest}
      className={classNames(style.mediaFilterBlock, className)}
      ref={ref}
    >
      {MEDIA_FILTER_TABS.map(({ key, variant, text }) => {
        const isFilterInactive = activeMediaFilter
          ? activeMediaFilter !== key
          : false

        return (
          <MediaFilterTab
            key={key}
            variant={variant}
            text={text}
            onClick={() =>
              setActiveMediaFilter((prevState) =>
                prevState === key ? undefined : key
              )
            }
            isInactive={isFilterInactive}
          />
        )
      })}
    </div>
  )
})

MediaFilterBlock.defaultProps = {
  className: '',
  activeMediaFilter: undefined,
}

MediaFilterBlock.propTypes = {
  className: PropTypes.string,
  activeMediaFilter: PropTypes.string,
  setActiveMediaFilter: PropTypes.func.isRequired,
}

export default MediaFilterBlock
