import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

import * as style from './CardStats.module.scss'

const CardStats = (props) => {
  const { className, ...rest } = props

  return (
    <div {...rest} className={classNames('card', style.cardStats, className)}>
      <div className={style.statsGrid}>
        <div>
          <h5>TOTAL STAKED</h5>
          <span>
            <b>25</b> BLOCK
          </span>
        </div>
        <div>
          <h5>TOTAL WITHDRAW</h5>
          <span>
            <b>28.64</b> BLOCK
          </span>
        </div>
        <div>
          <h5>IN STAKING</h5>
          <span>
            <b>20</b> BLOCK
          </span>
        </div>
        <div>
          <h5>NOW EARNED</h5>
          <span>
            <b>1.26</b> BLOCK
          </span>
        </div>
      </div>
      <div className={style.btnWrapper}>
        <Button variant="neon">CLAIM REWARDS</Button>
      </div>
    </div>
  )
}

CardStats.defaultProps = {
  className: '',
}

CardStats.propTypes = {
  className: PropTypes.string,
}

export default CardStats
