import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LogoWithCircleBackground from '~components/BlockassetLogo/assets/LogoWithCircleBackground'

import * as style from './BlockBalance.module.scss'

const BlockBalance = (props) => {
  const { className, balance, variant, ...rest } = props

  return (
    <div
      {...rest}
      className={classNames(
        style.blockBalance,
        { [style[variant]]: variant },
        className
      )}
    >
      <LogoWithCircleBackground
        width="24px"
        height="24px"
        className={style.logo}
      />
      <span className={style.textLabel}>YOUR BALANCE</span>
      <div className="text-joyride-13">{balance.toFixed(2)} BLOCK</div>
    </div>
  )
}

BlockBalance.defaultProps = {
  className: '',
  variant: '',
}

BlockBalance.propTypes = {
  className: PropTypes.string,
  balance: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['borderless', 'copy']),
}

export default BlockBalance
