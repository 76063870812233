import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Modal } from 'react-bootstrap'

import ModalWrapper from '~components/ModalWrapper'

import * as style from './ModalConfirmStaking.module.scss'

const ModalConfirmStaking = (props) => {
  const { title, className, show, onHide, nftCount, data, onConfirm, ...rest } =
    props

  return (
    <ModalWrapper
      {...rest}
      show={show}
      onHide={onHide}
      title={title}
      className={classNames(style.modalConfirmStaking, className)}
    >
      <Modal.Body className={style.modalBody}>
        <table>
          <tbody>
            <tr>
              <td>Staked quantity</td>
              <td className={style.textNftCount}>
                <span className="text-color-gradient-primary">
                  {nftCount} NFTs
                </span>
              </td>
            </tr>
            {data.map(({ label, value }) => (
              <tr key={label}>
                <td>{label}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button variant="neon" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Body>
    </ModalWrapper>
  )
}

ModalConfirmStaking.defaultProps = {
  title: 'Confirm Nft Staking',
  className: '',
  data: [],
}

ModalConfirmStaking.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  nftCount: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default ModalConfirmStaking
