import React, { Suspense, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { trim } from 'lodash'

import Header from '~components/Header'
import Footer from '~components/Footer'

import SVGDefs from '~components/SVGDefs'
import OverlayContext from '~contexts/OverlayContext'
import HeaderNavMenu from '~components/HeaderNavMenu'

// import SubNavMenu from './components/SubNavMenu'
import ToastContainerCustom from './components/ToastContainer'
import { MENU_SUB_ITEMS } from './constants'

import * as style from './style.module.scss'

const ModalConnectWallet = React.lazy(() =>
  import(
    /* webpackChunkName: "modal-connect-wallet" */ './components/ModalConnectWallet'
  )
)

const Layout = ({ children }) => {
  const { isModalConnectWalletVisible, hideModalConnectWallet } =
    useContext(OverlayContext)

  const { pathname } = useLocation()

  const showSubNavMenu = useMemo(
    () =>
      !!MENU_SUB_ITEMS?.find(
        (subItem) =>
          (trim(subItem.href, '/') || '/') === (trim(pathname, '/') || '/')
      ),
    [pathname]
  )

  return (
    <div className={style.layout}>
      <SVGDefs />
      <Suspense fallback={<div />}>
        <ModalConnectWallet
          show={isModalConnectWalletVisible}
          onHide={hideModalConnectWallet}
        />
      </Suspense>
      <ToastContainerCustom />
      <Header />
      <main className={style.main}>
        {showSubNavMenu && (
          <div className={style.navMenuScrollOuter}>
            <div className={style.navMenuScrollInner}>
              <Container className={style.navMenuContainer}>
                {/* <SubNavMenu /> */}
                <HeaderNavMenu />
              </Container>
            </div>
          </div>
        )}
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
