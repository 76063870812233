import React from 'react'
import classNames from 'classnames'

import Icon from '~components/Icon'

import * as style from './LogoWithCircleBackground.module.scss'

// eslint-disable-next-line react/prop-types
const LogoWithCircleBackground = ({ width, height, className, ...rest }) => (
  <div
    className={classNames(style.circleBg, className)}
    {...rest}
    style={{ width, height }}
  >
    <Icon
      name="icon-blockasset-logo"
      isFromInlineDefs
      size={['54%', 'auto']}
      viewbox={[351.6, 406]}
    />
  </div>
)

export default LogoWithCircleBackground
