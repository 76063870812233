import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useAuth from '~hooks/useAuth'
import SEO from '~components/SEO'

import CardInfo from '../CardInfo'
import CardStakeBlock from './components/CardStakeBlock'
import CardStakeCalculator from './components/CardStakeCalculator'
import CardStats from './components/CardStats'
import * as style from './BlockTokenStaking.module.scss'

const APY_AMOUNT = 30

const BlockTokenStaking = (props) => {
  const { className, isDesktop, ...rest } = props

  const { isLoggedIn } = useAuth()

  const APY_DATA = [
    {
      label: 'APY',
      value: `${APY_AMOUNT}%`,
      variant: 'scalable',
    },
    {
      label: 'DAILY ROI',
      value: '0.082%',
      icon: 'trend',
      variant: 'scalable',
    },
  ]

  return (
    <>
      <SEO title="Token Staking" />
      <div {...rest} className={classNames(style.blockMain, className)}>
        <CardInfo data={APY_DATA} />
        {isLoggedIn ? (
          <>
            <CardStakeBlock
              apyAmount={APY_AMOUNT}
              className={style.stakeCalculator}
            />
            <CardStats />
          </>
        ) : (
          <CardStakeCalculator
            apyAmount={APY_AMOUNT}
            className={style.stakeCalculator}
          />
        )}
      </div>
    </>
  )
}

BlockTokenStaking.defaultProps = {
  className: '',
  isDesktop: false,
}

BlockTokenStaking.propTypes = {
  className: PropTypes.string,
  isDesktop: PropTypes.bool,
}

export default BlockTokenStaking
