import { createContext } from 'react'

export default createContext({
  isModalConnectWalletVisible: false,
  setIsModalConnectWalletVisible: () => {},
  hideModalConnectWallet: () => {},
  showModalConnectWallet: () => {},
  toastStack: [],
  addToastToStack: () => {},
})
