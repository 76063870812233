import React, { useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SEO from '~components/SEO'
import { MOCK_NFTS_12 } from '~utils/mocks'
import useAuth from '~hooks/useAuth'

import CardInfo from '../CardInfo'

import CardNFT from './components/CardNFT'
import Banner from './components/Banner'
import * as style from './BlockNftStaking.module.scss'

const StakeCart = React.lazy(() =>
  import(/* webpackChunkName: "lazy-stake-cart" */ './components/StakeCart')
)

const BlockNftStaking = (props) => {
  const { className, ...rest } = props

  const { isLoggedIn } = useAuth()

  const [nfts, setNfts] = useState(MOCK_NFTS_12)

  const handleToggleNftSelection = (id) => {
    setNfts((prevStateNfts) =>
      prevStateNfts?.map((nft) =>
        nft.id === id ? { ...nft, isSelected: !nft.isSelected } : nft
      )
    )
  }

  const deselectAll = () =>
    setNfts((prevStateNfts) =>
      prevStateNfts?.map((nft) => ({ ...nft, isSelected: false }))
    )

  const stakedNfts = nfts?.filter((nft) => nft.isSelected)

  const isStakeFull = stakedNfts.length > 11

  return (
    <>
      <SEO title="NFT Staking" />
      <div {...rest} className={classNames(style.blockNftStaking, className)}>
        <Banner />
        {isLoggedIn && nfts ? (
          <>
            <div className="grid-asset-hub-nfts">
              {nfts.map((nftData) => (
                <CardNFT
                  key={nftData.id}
                  onToggle={handleToggleNftSelection}
                  isDisabled={isStakeFull && !nftData.isSelected}
                  {...nftData}
                />
              ))}
            </div>
            <Suspense fallback={<div />}>
              <StakeCart
                stakedNfts={stakedNfts}
                handleDeselectAll={deselectAll}
                handleToggleNftSelection={handleToggleNftSelection}
              />
            </Suspense>
          </>
        ) : (
          <CardInfo
            data={[
              {
                label: 'NFT Staked',
                value: '433448',
                colorVariant: 'orange',
                iconName: 'layers',
              },
              {
                label: '% LOCKED',
                value: '62,4%',
                colorVariant: 'orange',
              },
              {
                label: 'MIN $ VALUE',
                value: '$5,739,824.69',
                colorVariant: 'orange',
                iconName: 'dollar',
              },
            ]}
            variant="dark"
            isGridArea
          />
        )}
      </div>
    </>
  )
}

BlockNftStaking.defaultProps = {
  className: '',
}

BlockNftStaking.propTypes = {
  className: PropTypes.string,
}

export default BlockNftStaking
