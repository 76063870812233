import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactPlayer from 'react-player/youtube'
import dayjs from 'dayjs'

import Icon from '~components/Icon'

import PlayIcon from '../PlayIcon'
import * as style from './CardVideo.module.scss'

const CardVideo = forwardRef((props, ref) => {
  const {
    className,
    videoUrl,
    title,
    timestamp,
    playIconVariant,
    onPlay,
    onPause,
    onEnded,
    variant,
    thumbnailUrl,
    playerRef,
    ...rest
  } = props

  const youtubeConfig = {
    playerVars: {
      // This parameter lets you use a YouTube player that does not show a YouTube logo
      modestbranding: 1,
      // This parameter controls whether videos play inline or fullscreen on iOS
      playsinline: 0,
      // This parameter indicates whether the player should show related videos
      // when playback of the initial video ends
      rel: 0,
    },
  }

  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const handleStartPlaying = () => {
    setIsVideoPlaying(true)
    onPlay()
  }
  const handleOnPause = () => {
    setIsVideoPlaying(false)
    onPause()
  }
  const handleOnEnded = () => {
    setIsVideoPlaying(false)
    onEnded()
  }

  useImperativeHandle(ref, () => ({ pausePlayback: handleOnPause }))

  return (
    <div
      {...rest}
      className={classNames(
        style.cardVideo,
        { [style[variant]]: variant },
        className
      )}
      ref={ref}
    >
      <h5 className={classNames('font-family-syne', 'text-syne-18-bold')}>
        {title}
      </h5>
      <div className={style.videoWrapperOuter}>
        <div
          className={classNames(style.videoWrapperInner, {
            [style.glowVisible]: !isVideoPlaying,
          })}
        >
          <ReactPlayer
            url={videoUrl}
            light={thumbnailUrl || true}
            config={{ youtube: youtubeConfig }}
            className={style.playerRoot}
            width={null}
            height={null}
            controls
            onPlay={handleStartPlaying}
            onPause={handleOnPause}
            onEnded={handleOnEnded}
            playing={isVideoPlaying}
            playIcon={<PlayIcon variant={playIconVariant} />}
            ref={playerRef}
          />
        </div>
      </div>
      <div className={classNames('text-syne-14', style.cardFooter)}>
        <a
          href={videoUrl}
          target="_blank"
          rel="nofollow noopener noreferrer"
          className={style.ytLink}
        >
          <Icon name="icon-youtube" size={24} />
        </a>
        <span>{dayjs.unix(timestamp).format('HH:mm[,] DD MMM YYYY')}</span>
      </div>
    </div>
  )
})

CardVideo.defaultProps = {
  className: '',
  playIconVariant: undefined,
  onPlay: () => {},
  onPause: () => {},
  onEnded: () => {},
  variant: undefined,
  thumbnailUrl: undefined,
  playerRef: null,
}

CardVideo.propTypes = {
  className: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  playIconVariant: PropTypes.oneOf(['hero']),
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  variant: PropTypes.oneOf(['hero']),
  thumbnailUrl: PropTypes.string,
  playerRef: PropTypes.any,
}

export default CardVideo
