import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LogoWithCircleBackground from '~components/BlockassetLogo/assets/LogoWithCircleBackground'
import Icon from '~components/Icon'

import * as style from './BlockTokenPrice.module.scss'

const BlockTokenPrice = (props) => {
  const { className, variant, isDropdownOpen, ...rest } = props

  return (
    <div
      {...rest}
      className={classNames(
        style.blockTokenPrice,
        { [style[variant]]: variant },
        { [style.dropdownOpen]: isDropdownOpen },
        className
      )}
    >
      <LogoWithCircleBackground
        width="24px"
        height="24px"
        className={style.logo}
      />
      <span className={style.textLabel}>PRICE</span>
      <div className="text-joyride-13">
        <span className={style.textPrice}>$1.29</span>/BLOCK
      </div>
      <Icon
        name="icon-chevron-down-wallet"
        size={12}
        className={style.iconChevron}
      />
    </div>
  )
}

BlockTokenPrice.defaultProps = {
  className: '',
  variant: undefined,
  isDropdownOpen: false,
}

BlockTokenPrice.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['dropdown', 'standalone']),
  isDropdownOpen: PropTypes.bool,
}

export default BlockTokenPrice
