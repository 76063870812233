import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '~components/Icon'

import * as style from './PlayIcon.module.scss'

const PlayIcon = (props) => {
  const { className, variant, ...rest } = props

  return (
    <div
      {...rest}
      className={classNames(
        'react-player-play-icon',
        style.playButton,
        { [style[variant]]: variant },
        className
      )}
    >
      <Icon name="icon-play" size={20} />
    </div>
  )
}

PlayIcon.defaultProps = {
  className: '',
  variant: undefined,
}

PlayIcon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['hero']),
}

export default PlayIcon
