import React from 'react'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Social from '~components/Social'
import BlockassetLogo from '~components/BlockassetLogo'

import { BOTTOM_NAV, LINKS } from './constants'
import * as style from './style.module.scss'

const Footer = () => {
  const fullYear = new Date().getFullYear()

  return (
    <footer className={style.footer}>
      <Container className={classNames('text-syne-14', style.footerInner)}>
        <div className={style.footerTop}>
          <div className={classNames(style.blockFooterBox, style.blockLogo)}>
            <BlockassetLogo variant="footer" />
            <Social variant="footer" />
          </div>
          {BOTTOM_NAV.map(({ heading, items }) => (
            <div key={heading} className={style.blockFooterBox}>
              <p className={classNames('text-syne-18-bold', style.navHeading)}>
                {heading}
              </p>
              <ul className={style.topNavLinksList}>
                {items.map(({ text, href }) => (
                  <li key={text}>
                    <Link to={href}>{text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className={style.footerBottom}>
          <p>
            &copy; {fullYear} Blockasset. <span>All rights reserved</span>
          </p>
          <ul className={style.bottomLinks}>
            {LINKS.map(({ name, link }) => (
              <li className={style.bottomLinksItem} key={name}>
                <a
                  className={style.bottomLinksLink}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href={link}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
