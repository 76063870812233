// extracted by mini-css-extract-plugin
export var cardNft = "CardNFT_cardNft__U4ivE";
export var selected = "CardNFT_selected__akJ7J";
export var drop = "CardNFT_drop__Pc9SU";
export var imgAspectRatioContainer = "CardNFT_imgAspectRatioContainer__47Yvl";
export var imgOverlay = "CardNFT_imgOverlay__ncHMZ";
export var imgOwner = "CardNFT_imgOwner__19hcW";
export var imgNft = "CardNFT_imgNft__fKkgU";
export var gridAthlete = "CardNFT_gridAthlete__6SAEk";
export var textAthleteName = "CardNFT_textAthleteName__sY+mv";
export var imgAthlete = "CardNFT_imgAthlete__kLDmZ";
export var textBuyNow = "CardNFT_textBuyNow__wLQWE";
export var textPrice = "CardNFT_textPrice__ttI1c";
export var rotate = "CardNFT_rotate__EDkSt";
export var dotty = "CardNFT_dotty__CP3Ue";