import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

import Icon from '~components/Icon'

import * as style from './ButtonClaim.module.scss'

const ButtonClaim = ({ isXxl }) => (
  <Button variant="orange" className={style.btnClaim}>
    <span className={style.iconWrap}>
      <Icon name="icon-logo-asset" size={[13, 15]} isFromInlineDefs />
    </span>
    <span>claim {isXxl && 'all'} 348.07 ASSET</span>
  </Button>
)

ButtonClaim.defaultProps = {
  isXxl: true,
}

ButtonClaim.propTypes = {
  isXxl: PropTypes.bool,
}

export default ButtonClaim
