import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import useAuth from '~hooks/useAuth'
import { TOP_NAV_ITEMS } from '~utils/constants'

import NavSelect from './components/NavSelect'
import BlockTokenPrice from './components/BlockTokenPrice'
import * as style from './CardSideNav.module.scss'

const CardSideNav = (props) => {
  const { className, ...rest } = props

  const { isLoggedIn } = useAuth()

  const isBtwMdLg = useMediaQuery({
    query: '(min-width: 640px) and (max-width: 1023.98px)',
  })

  const { subItems } = TOP_NAV_ITEMS[0]

  return (
    <div {...rest} className={classNames('card', className)}>
      <div className={style.innerContent}>
        <BlockTokenPrice />
        {!isBtwMdLg ? (
          <>
            <hr className="hr-card" />
            <Nav variant="aside" activeKey="">
              {subItems.map(({ text, href, requiresLogIn, group }, index) => (
                <>
                  <Nav.Link
                    key={text}
                    as={NavLink}
                    end
                    to={href}
                    disabled={requiresLogIn && !isLoggedIn}
                  >
                    {text}
                  </Nav.Link>
                  {group !== (subItems?.[index + 1]?.group ?? group) && (
                    <hr className="hr-card" />
                  )}
                </>
              ))}
            </Nav>
          </>
        ) : (
          <NavSelect />
        )}
      </div>
    </div>
  )
}

CardSideNav.defaultProps = {
  className: '',
}

CardSideNav.propTypes = {
  className: PropTypes.string,
}

export default CardSideNav
