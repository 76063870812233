// extracted by mini-css-extract-plugin
export var cardStakeCalculator = "CardStakeCalculator_cardStakeCalculator__xbvMU";
export var cardHeading = "CardStakeCalculator_cardHeading__nC1A7";
export var hrHeading = "CardStakeCalculator_hrHeading__E0iq4";
export var blockPlusSign = "CardStakeCalculator_blockPlusSign__hOW7H";
export var blockSeparator = "CardStakeCalculator_blockSeparator__yvN4r";
export var hr = "CardStakeCalculator_hr__jPrdr";
export var blockInputs = "CardStakeCalculator_blockInputs__UEAxO";
export var textLabel = "CardStakeCalculator_textLabel__v6BHc";
export var textResult = "CardStakeCalculator_textResult__zX3Ae";
export var fontSmaller = "CardStakeCalculator_fontSmaller__XoSk9";
export var textBlock = "CardStakeCalculator_textBlock__1Xr4b";
export var rotate = "CardStakeCalculator_rotate__mF-rD";
export var dotty = "CardStakeCalculator_dotty__3HgRp";