import React from 'react'
import { Modal } from 'react-bootstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '~components/Icon'
import * as style from './ModalWrapper.module.scss'

const ModalWrapper = ({
  title,
  className,
  show,
  onHide,
  backdrop,
  contentClassName,
  headerClassName,
  children,
  isLoading,
  ...rest
}) => {
  const onHideConditional = () => {
    if (!isLoading) onHide()
  }

  return (
    <Modal
      {...rest}
      className={className}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      backdrop={isLoading ? 'static' : backdrop}
      onHide={onHideConditional}
      contentClassName={contentClassName}
    >
      <Modal.Header className={headerClassName}>
        <Modal.Title id="contained-modal-title-vcenter" as="h3">
          {title}
        </Modal.Title>
        <button
          className={classNames(style.btnClose, {
            [style.isLoading]: isLoading,
          })}
          type="button"
          onClick={onHideConditional}
        >
          <Icon name="icon-close" size={[24, 24]} />
        </button>
      </Modal.Header>
      {children}
    </Modal>
  )
}

ModalWrapper.defaultProps = {
  className: '',
  contentClassName: '',
  headerClassName: '',
  backdrop: true,
  isLoading: false,
}

ModalWrapper.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
}

export default ModalWrapper
