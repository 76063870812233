import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import classNames from 'classnames'

import { normalizeUrl } from '~utils'
import { TOP_NAV_ITEMS } from '~utils/constants'

import * as style from './HeaderNavMenu.module.scss'

const HeaderNavMenu = (props) => {
  const { className, ...rest } = props

  const { pathname } = useLocation()

  const activeNavItem = TOP_NAV_ITEMS.find(({ href, subItems }) =>
    href
      ? normalizeUrl(href) === normalizeUrl(pathname)
      : !!subItems.find(
          (subItem) => normalizeUrl(subItem.href) === normalizeUrl(pathname)
        )
  )

  return (
    <Nav
      activeKey={activeNavItem?.text}
      variant="white"
      className={classNames(style.headerNavMenu, className)}
      {...rest}
    >
      {TOP_NAV_ITEMS.map(({ text, href, subItems }) => (
        <Nav.Link
          key={text}
          eventKey={text}
          to={href || subItems?.[0]?.href}
          as={Link}
          className={style.navLink}
        >
          {text}
        </Nav.Link>
      ))}
    </Nav>
  )
}

HeaderNavMenu.defaultProps = {
  className: '',
}

HeaderNavMenu.propTypes = {
  className: PropTypes.string,
}

export default HeaderNavMenu
