import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import DataItem from '../DataItem'
import * as style from './CardInfo.module.scss'

const CardInfo = (props) => {
  const { className, data, variant, button, isGridArea, ...rest } = props

  const isLaptopOrMobile = useMediaQuery({ minWidth: 640, maxWidth: 1024 })

  return (
    <div
      {...rest}
      className={classNames(
        style.cardInfo,
        { [style[variant]]: variant },
        className
      )}
    >
      <div
        className={classNames(
          style.baseLayer,
          { [style.button]: button },
          className
        )}
      >
        {data.map((item, index) => (
          <DataItem
            key={item.label}
            {...item}
            style={{
              'grid-area':
                isGridArea && isLaptopOrMobile ? `item${index + 1}` : 'auto',
            }}
          />
        ))}
        {button && <div className={style.btnWrapper}>{button}</div>}
      </div>
    </div>
  )
}

CardInfo.defaultProps = {
  className: '',
  variant: '',
  button: null,
  isGridArea: false,
}

CardInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.string,
  button: PropTypes.element,
  isGridArea: PropTypes.bool,
}

export default CardInfo
