import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Badge } from 'react-bootstrap'
import * as style from './CardNFT.module.scss'

const CardNFT = (props) => {
  const {
    className,
    athlete,
    id,
    imgUrl,
    nftId,
    owner,
    isSelected,
    isDisabled,
    onToggle,
    ...rest
  } = props

  return (
    <button
      type="button"
      {...rest}
      className={classNames(
        style.cardNft,
        { [style.selected]: isSelected },
        className
      )}
      onClick={() => onToggle(id)}
      disabled={isDisabled}
    >
      <span className={style.imgAspectRatioContainer}>
        <img
          alt={`${athlete.firstName} ${athlete.lastName} #${nftId}`}
          src={imgUrl}
          className={style.imgNft}
        />
        <span className={style.imgOverlay}>
          <img
            src={owner.imgUrl}
            alt={owner.username}
            width={32}
            height={32}
            className={style.imgOwner}
          />
          <Badge bg="glass">#{nftId}</Badge>
        </span>
      </span>
      <span className={style.gridAthlete}>
        <img
          width={24}
          height={24}
          alt={`${athlete.firstName} ${athlete.lastName}`}
          src={athlete.imgUrl}
          className={style.imgAthlete}
        />
        <h2 className={classNames('text-joyride-12', style.textAthleteName)}>
          {athlete.firstName} {athlete.lastName}
        </h2>
      </span>
      <span className={style.drop} />
    </button>
  )
}

CardNFT.defaultProps = {
  className: '',
  isSelected: false,
  isDisabled: false,
  onToggle: () => {},
}

CardNFT.propTypes = {
  className: PropTypes.string,
  athlete: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    imgUrl: PropTypes.string,
  }).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  imgUrl: PropTypes.string.isRequired,
  nftId: PropTypes.number.isRequired,
  owner: PropTypes.shape({
    imgUrl: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onToggle: PropTypes.func,
}

export default CardNFT
