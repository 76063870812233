// extracted by mini-css-extract-plugin
export var cardStakeBlock = "CardStakeBlock_cardStakeBlock__SlTs+";
export var cardHeading = "CardStakeBlock_cardHeading__d+luN";
export var btnPresent = "CardStakeBlock_btnPresent__VgwKf";
export var hrHeading = "CardStakeBlock_hrHeading__ey3bx";
export var inputWrapper = "CardStakeBlock_inputWrapper__8r7ZV";
export var textBalance = "CardStakeBlock_textBalance__iP9Hb";
export var gridPercentButtons = "CardStakeBlock_gridPercentButtons__N47l4";
export var textResult = "CardStakeBlock_textResult__IrRro";
export var inputRange = "CardStakeBlock_inputRange__jSNvO";
export var btnStake = "CardStakeBlock_btnStake__2Dv+W";
export var table = "CardStakeBlock_table__b99Ao";
export var rotate = "CardStakeBlock_rotate__oTwKZ";
export var dotty = "CardStakeBlock_dotty__yAfgJ";