import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

import Icon from '~components/Icon'

import ModalRewardStore from '../ModalRewardStore'

import * as style from './Banner.module.scss'

const Banner = ({ header, descr }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => setIsModalVisible(true)
  const hideModale = () => setIsModalVisible(false)

  return (
    <>
      <ModalRewardStore show={isModalVisible} onHide={hideModale} />
      <div className={style.banner}>
        <div className={style.bannerInner}>
          <Icon
            name="icon-logo-asset"
            size={[82, 95]}
            isFromInlineDefs
            className={style.icon}
          />
          <div className={style.blockText}>
            <span className={classNames(style.textHeader, 'text-joyride-20')}>
              {header}
            </span>
            <h1
              className={classNames(
                style.textDescr,
                'text-color-gradient-orange'
              )}
            >
              {descr}
            </h1>
          </div>
          <div className={style.bg} />
        </div>
        <Button variant="orange" className={style.button} onClick={showModal}>
          GO TO ASSET REWARD STORE
        </Button>
      </div>
    </>
  )
}

Banner.defaultProps = {
  header: 'DAILY REWARD',
  descr: '10 ASSET per NFT',
}

Banner.propTypes = {
  header: PropTypes.string,
  descr: PropTypes.string,
}

export default Banner
