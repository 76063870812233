import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

import Icon from '~components/Icon'

import * as s from './Banner.module.scss'

const Banner = ({ header, descr }) => {
  return (
    <div className={s.banner}>
      <div className={s.bannerInner}>
        <h2 className={s.header}>{header}</h2>
        <p className="lead">{descr}</p>
        <div className={s.bg} />
      </div>
      <Button
        as="a"
        variant="neon-solid"
        className={s.button}
        href="https://www.youtube.com/c/blockasset"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <Icon name="icon-play" size={20} />
        <span>YOUTUBE</span>
      </Button>
    </div>
  )
}

Banner.defaultProps = {
  header: 'BLOCKASSET MEDIA',
  descr: 'See our announcements first',
}

Banner.propTypes = {
  header: PropTypes.string,
  descr: PropTypes.string,
}

export default Banner
