// extracted by mini-css-extract-plugin
export var headerWrapper = "Header_headerWrapper__Y0JL0";
export var header = "Header_header__OKWO7";
export var navMenu = "Header_navMenu__AWtZ0";
export var gridHeaderRight = "Header_gridHeaderRight__ppSS8";
export var btnWrapper = "Header_btnWrapper__hwsml";
export var logo = "Header_logo__shVBB";
export var btnMenu = "Header_btnMenu__2mG+G";
export var btnWallet = "Header_btnWallet__PW-rs";
export var rotate = "Header_rotate__FL-ne";
export var dotty = "Header_dotty__8EyQ1";