import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import useAuth from '~hooks/useAuth'
import Icon from '~components/Icon'

const HeaderNavDropdown = (props) => {
  const { className, text: parentText, subItems, ...rest } = props

  const { isLoggedIn } = useAuth()

  return (
    <div>
      <Dropdown {...rest} className={className}>
        <Dropdown.Toggle
          as="button"
          className="dropdown-toggle-dark-header-nav"
        >
          <span>{parentText}</span>
          <Icon name="icon-chevron-down-wallet" size={12} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          variant="dark-header-nav"
          popperConfig={{
            modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
          }}
        >
          <Nav variant="aside" activeKey="">
            {subItems.map(({ text, href, requiresLogIn }) => (
              <Nav.Link
                key={text}
                as={NavLink}
                to={href}
                eventKey={href}
                disabled={requiresLogIn && !isLoggedIn}
              >
                {text}
              </Nav.Link>
            ))}
          </Nav>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

HeaderNavDropdown.defaultProps = {
  className: '',
}

HeaderNavDropdown.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, href: PropTypes.string })
  ).isRequired,
}

export default HeaderNavDropdown
