// extracted by mini-css-extract-plugin
export var banner = "Banner_banner__A9Brn";
export var bannerInner = "Banner_bannerInner__mBCZo";
export var textHeader = "Banner_textHeader__9Am-B";
export var textDescr = "Banner_textDescr__-LEUQ";
export var icon = "Banner_icon__cF1AP";
export var blockText = "Banner_blockText__aCUSB";
export var bg = "Banner_bg__U6JuV";
export var button = "Banner_button__re9dl";
export var rotate = "Banner_rotate__J1FWO";
export var dotty = "Banner_dotty__8ZjmV";