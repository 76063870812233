import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './MediaFilterTab.module.scss'

const MediaFilterTab = (props) => {
  const { className, variant, text, isInactive, ...rest } = props

  return (
    <button
      {...rest}
      type="button"
      className={classNames(
        style.mediaFilterTab,
        'text-joyride-20',
        { [style[variant]]: variant, [style.inactive]: isInactive },
        className
      )}
    >
      <span>{text}</span>
    </button>
  )
}

MediaFilterTab.defaultProps = {
  className: '',
  variant: 'purple',
  isInactive: false,
}

MediaFilterTab.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['purple', 'yellow', 'rainbow', 'primary']),
  isInactive: PropTypes.bool,
}

export default MediaFilterTab
