import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'

import { TOP_NAV_ITEMS } from '~utils/constants'

import HeaderNavDropdown from '../HeaderNavDropdown'

const HeaderNavMenu = (props) => {
  const { className, ...rest } = props

  return (
    <Nav variant="header" className={className} as="ul" {...rest}>
      {TOP_NAV_ITEMS.map(({ text, href, subItems }) =>
        subItems?.length ? (
          <Nav.Item key={text} as="li" className="nav-item--dropdown">
            <HeaderNavDropdown text={text} subItems={subItems} />
          </Nav.Item>
        ) : (
          <Nav.Item key={text} as="li">
            <Nav.Link to={href ?? '/'} as={Link}>
              {text}
            </Nav.Link>
          </Nav.Item>
        )
      )}
    </Nav>
  )
}

HeaderNavMenu.defaultProps = {
  className: '',
}

HeaderNavMenu.propTypes = {
  className: PropTypes.string,
}

export default HeaderNavMenu
