import React, { useState } from 'react'
import OverlayContext from '~contexts/OverlayContext'

const OverlayStateProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...rest } = props

  const [isModalConnectWalletVisible, setIsModalConnectWalletVisible] =
    useState(false)

  const [toastStack, setToastStack] = useState([])
  const addToastToStack = (toast) =>
    setToastStack((prevState) => [...prevState, toast])

  const hideModalConnectWallet = () => setIsModalConnectWalletVisible(false)
  const showModalConnectWallet = () => setIsModalConnectWalletVisible(true)

  return (
    <OverlayContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isModalConnectWalletVisible,
        setIsModalConnectWalletVisible,
        hideModalConnectWallet,
        showModalConnectWallet,
        toastStack,
        addToastToStack,
      }}
      {...rest}
    >
      {children}
    </OverlayContext.Provider>
  )
}

export default OverlayStateProvider
