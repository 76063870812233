import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'

import Paginator from '~components/Paginator'

import MediaFilterBlock from './components/MediaFilterBlock'
import CardVideo from './components/CardVideo'
import Banner from './components/Banner'
import HeroSlider from './components/HeroSlider'
import { MEDIA_PAGE_VIDEO_CARDS } from './mocks'

import * as style from './MediaPage.module.scss'

const MediaPage = (props) => {
  const { className, ...rest } = props

  const [activeMediaFilter, setActiveMediaFilter] = useState()

  const filterBlockRef = useRef()

  const handlePageChange = () => {
    filterBlockRef.current?.scrollIntoView()
  }

  return (
    <Container {...rest} className={classNames(style.mediaPage, className)}>
      <Banner />
      <HeroSlider className={style.heroSlider} />
      <MediaFilterBlock
        activeMediaFilter={activeMediaFilter}
        setActiveMediaFilter={setActiveMediaFilter}
        ref={filterBlockRef}
      />
      <div className={style.videoDeck}>
        {MEDIA_PAGE_VIDEO_CARDS.map(({ title, videoUrl, timestamp, id }) => (
          <CardVideo
            key={id}
            title={title}
            videoUrl={videoUrl}
            timestamp={timestamp}
          />
        ))}
      </div>
      <Paginator
        pageCount={12}
        className={style.paginator}
        onPageChange={handlePageChange}
      />
    </Container>
  )
}

MediaPage.defaultProps = {
  className: '',
}

MediaPage.propTypes = {
  className: PropTypes.string,
}

export default MediaPage
