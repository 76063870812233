const getTrackBackgroundGradient = ({
  values,
  filledGradientColors,
  emptyGradientColors,
  min,
  max,
}) => {
  const progress = ((values[0] - min) / (max - min)) * 100

  return `rgba(0, 0, 0, 0) linear-gradient(to right, ${filledGradientColors[0]} 0%, ${filledGradientColors[1]} ${progress}%, ${emptyGradientColors[0]} ${progress}%, ${emptyGradientColors[1]} 100%) repeat scroll 0% 0%`
}

export default getTrackBackgroundGradient
