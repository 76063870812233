import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './SVGDefs.module.scss'

const SVGDefs = (props) => {
  const { className, ...rest } = props

  return (
    <svg
      {...rest}
      className={classNames(style.svgDefs, className)}
      aria-hidden="true"
      focusable="false"
      tabIndex={-1}
    >
      <symbol id="icon-blockasset-logo" viewBox="0 0 351.6 406">
        <path
          fill="url(#logoA)"
          d="M252.8 247.45v-88.91l-77-44.45-77 44.45v88.91l77 44.46z"
        />
        <path
          fill="url(#logoB)"
          d="m175.8 0-77 44.46v55.19l77-44.45 128 73.9v147.8l-128 73.9-128-73.9v-203L0 101.5v203L175.8 406l175.8-101.5v-203z"
        />
        <path
          opacity=".2"
          d="m175.8 291.91 77-44.46-77.24-44.8-76.76 44.8 77 44.46"
        />
        <path
          opacity=".2"
          d="m175.8 350.8-128-73.9L0 304.5l175.56 101.36L351.6 304.5l-47.8-27.6-128 73.9"
        />
        <path
          opacity=".05"
          d="M0 101.5v203l47.77-27.68V73.92zm175.8-46.3V0l-77 44.46v55.19m0 58.89v88.72l77-44.61v-88.56"
        />
      </symbol>
      <symbol id="icon-logo-asset" fill="none" viewBox="0 0 82 95">
        <path d="M23 36.5L41 47V67L23 56.5V36.5Z" fill="url(#assetA)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 23V70.5L11 77L11.001 64L11 29.5L0 23ZM23 71.1996V84.1998L41.001 95L41 82L23 71.1996Z"
          fill="url(#assetB)"
        />
        <path d="M41 47L59 36.5V56.5L41 67V47Z" fill="url(#assetC)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82 70.5V23L71 29.5L71.001 64L71 77L82 70.5ZM59 84.2V71.2006L41.001 82L41 95L59 84.2Z"
          fill="url(#assetD)"
        />
        <path
          d="M0 23L41 0L82 23L71 29.5L41 12L11 29.5L0 23Z"
          fill="url(#assetF)"
        />
        <path
          d="M41 26L58.8477 36.5894L41 47L23 36.5005L41 26Z"
          fill="url(#assetE)"
        />
      </symbol>
      <defs>
        <linearGradient
          id="logoA"
          gradientUnits="userSpaceOnUse"
          x1="51.99"
          y1="79.19"
          x2="299.81"
          y2="327"
        >
          <stop offset=".09" stopColor="#21daba" />
          <stop offset=".85" stopColor="#8c52ff" />
        </linearGradient>
        <linearGradient
          id="logoB"
          gradientUnits="userSpaceOnUse"
          x1="51.45"
          y1="78.64"
          x2="432.65"
          y2="459.85"
        >
          <stop offset="0" stopColor="#21daba" />
          <stop offset=".72" stopColor="#695aff" />
        </linearGradient>
        <linearGradient id="gradSuccess" x2="1" y2="1">
          <stop offset="0%" stopColor="#4B98CD" />
          <stop offset="100%" stopColor="#1FCFB1" />
        </linearGradient>
        <linearGradient
          id="assetA"
          x1="-10.5"
          y1="39.5"
          x2="51"
          y2="95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED3F05" />
          <stop offset="1" stopColor="#FFCA0C" />
        </linearGradient>
        <linearGradient
          id="assetB"
          x1="-10.5"
          y1="39.5"
          x2="51"
          y2="95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED3F05" />
          <stop offset="1" stopColor="#FFCA0C" />
        </linearGradient>
        <linearGradient
          id="assetC"
          x1="29.5"
          y1="38.5"
          x2="89"
          y2="85.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38901" />
          <stop offset="1" stopColor="#FFD600" />
        </linearGradient>
        <linearGradient
          id="assetD"
          x1="29.5"
          y1="38.5"
          x2="89"
          y2="85.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38901" />
          <stop offset="1" stopColor="#FFD600" />
        </linearGradient>
        <linearGradient
          id="assetF"
          x1="31"
          y1="-2"
          x2="70.5"
          y2="36.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E52A00" />
          <stop offset="1" stopColor="#EF8900" />
        </linearGradient>
        <linearGradient
          id="assetE"
          x1="31"
          y1="-2"
          x2="70.5"
          y2="36.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E52A00" />
          <stop offset="1" stopColor="#EF8900" />
        </linearGradient>
        <linearGradient
          id="item-gradient-primary"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#4B98CD" />
          <stop offset="100%" stopColor="#1FCFB1" />
        </linearGradient>
        <linearGradient
          id="item-gradient-orange"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#f68409" />
          <stop offset="100%" stopColor="#f8c301" />
        </linearGradient>
      </defs>
    </svg>
  )
}

SVGDefs.defaultProps = {
  className: '',
}

SVGDefs.propTypes = {
  className: PropTypes.string,
}

export default SVGDefs
