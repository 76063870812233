import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import classNames from 'classnames'

import CardVideo from '../CardVideo'
import { MEDIA_PAGE_HERO_VIDEOS } from '../../mocks'

import * as style from './HeroSlider.module.scss'

const HeroSlider = (props) => {
  const { className, ...rest } = props

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dotsClass: style.dots,
    pauseOnFocus: false,
    pauseOnHover: true,
    adaptiveHeight: true,
  }

  const slider = useRef()
  const videos = useRef([])

  const pauseAutoplay = () => {
    slider.current?.slickPause()
  }

  const resumeAutoplay = () => {
    slider.current?.slickPlay()
  }

  const handleSlideChange = () => {
    if (!videos.current.length) return

    for (let i = 0; i < videos.current.length; i += 1) {
      videos.current[i].pausePlayback()
    }
  }

  return (
    <div {...rest} className={classNames(style.sliderContainer, className)}>
      <Slider {...settings} ref={slider} beforeChange={handleSlideChange}>
        {MEDIA_PAGE_HERO_VIDEOS.map(
          ({ videoUrl, title, timestamp, id, thumbnailUrl }, index) => (
            <div key={id}>
              <CardVideo
                videoUrl={videoUrl}
                title={title}
                timestamp={timestamp}
                playIconVariant="hero"
                onPlay={pauseAutoplay}
                onEnded={resumeAutoplay}
                onPause={resumeAutoplay}
                playing={false}
                variant="hero"
                thumbnailUrl={thumbnailUrl}
                ref={(element) => {
                  videos.current[index] = element
                }}
              />
            </div>
          )
        )}
      </Slider>
    </div>
  )
}

HeroSlider.defaultProps = {
  className: '',
}

HeroSlider.propTypes = {
  className: PropTypes.string,
}

export default HeroSlider
