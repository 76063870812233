import React from 'react'
import PropTypes from 'prop-types'
import { Range } from 'react-range'
import classNames from 'classnames'

import * as style from './InputRange.module.scss'
import getTrackBackgroundGradient from './getTrackBackgroundGradient'

const InputRange = (props) => {
  const { className, step, min, max, values, onChange, ...rest } = props

  return (
    <div className={classNames(style.wrapper, className)}>
      <Range
        step={step}
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        renderTrack={({ props: renderProps, children }) => (
          <div
            {...renderProps}
            className={style.track}
            style={{
              background: getTrackBackgroundGradient({
                values,
                filledGradientColors: ['#4B98CD', '#1FCFB1'],
                emptyGradientColors: ['#181818', '#181818'],
                min,
                max,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props: renderProps }) => (
          <div {...renderProps} className={style.thumb} />
        )}
        {...rest}
      />
    </div>
  )
}

InputRange.defaultProps = {
  className: '',
}

InputRange.propTypes = {
  className: PropTypes.string,
  step: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default InputRange
