import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'

import ModalWrapper from '~components/ModalWrapper'
import Icon from '~components/Icon'

import * as style from './ModalRewardStore.module.scss'

const ModalRewardStore = (props) => {
  const { className, show, onHide, nftCount, ...rest } = props

  return (
    <ModalWrapper
      {...rest}
      show={show}
      onHide={onHide}
      title="Asset reward store"
      contentClassName={style.modalContent}
      headerClassName={style.modalHeader}
      className={classNames(style.modalRewardStore, className)}
    >
      <Modal.Body className={style.modalBody}>
        <h3
          className={classNames(
            style.description,
            'text-color-gradient-orange'
          )}
        >
          Coming soon
        </h3>

        <div className={style.iconWrap}>
          <Icon name="icon-logo-asset" size={[135, 155]} isFromInlineDefs />
        </div>
      </Modal.Body>
    </ModalWrapper>
  )
}

ModalRewardStore.defaultProps = {
  className: '',
}

ModalRewardStore.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  nftCount: PropTypes.number.isRequired,
}

export default ModalRewardStore
