import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Badge, Button } from 'react-bootstrap'
import * as style from './CardNFTStaked.module.scss'

const CardNFTStaked = (props) => {
  const {
    className,
    athlete,
    id,
    imgUrl,
    nftId,
    owner,
    isSelected,
    onToggle,
    onUnstake,
    claimAmount,
    ...rest
  } = props

  return (
    <div {...rest} className={classNames(style.cardNft, className)}>
      <span className={style.imgAspectRatioContainer}>
        <img
          alt={`${athlete.firstName} ${athlete.lastName} #${nftId}`}
          src={imgUrl}
          className={style.imgNft}
        />
        <span className={style.imgOverlay}>
          <img
            src={owner.imgUrl}
            alt={owner.username}
            width={32}
            height={32}
            className={style.imgOwner}
          />
          <Badge bg="glass">#{nftId}</Badge>
        </span>
      </span>
      <table className={classNames('text-syne-12-bold', style.table)}>
        <tbody>
          <tr>
            <td>Daily Reward</td>
            <td className="text-color-gradient-primary">1 BLOCK</td>
          </tr>
        </tbody>
      </table>
      {!!claimAmount && (
        <Button variant="neon" className={style.btnClaim}>
          claim {claimAmount} BLOCK
        </Button>
      )}
      <Button
        variant="orange"
        className={style.cardUnstake}
        onClick={onUnstake}
      >
        unstake
      </Button>
    </div>
  )
}

CardNFTStaked.defaultProps = {
  className: '',
  isSelected: false,
  onToggle: () => {},
  onUnstake: () => {},
  claimAmount: 0,
}

CardNFTStaked.propTypes = {
  className: PropTypes.string,
  athlete: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    imgUrl: PropTypes.string,
  }).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  imgUrl: PropTypes.string.isRequired,
  nftId: PropTypes.number.isRequired,
  owner: PropTypes.shape({
    imgUrl: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  onToggle: PropTypes.func,
  onUnstake: PropTypes.func,
  claimAmount: PropTypes.number,
}

export default CardNFTStaked
