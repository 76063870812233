import React, { useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { clamp, round } from 'lodash'

import LogoWithCircleBackground from '~components/BlockassetLogo/assets/LogoWithCircleBackground'
import InputWithLabel from '~components/InputWithLabel'
import InputRange from '~components/InputRange'
import Icon from '~components/Icon'
import useAuth from '~hooks/useAuth'
import { limitMaxValue, maskNumberValue } from '~utils'

import * as style from './CardStakeBlock.module.scss'
import { STAKE_PERCENT_OPTIONS } from './constants'

const ModalFeatures = React.lazy(() =>
  import(/* webpackChunkName: "modal-features" */ '../ModalFeatures')
)

const CardStakeBlock = (props) => {
  const { className, apyAmount, ...rest } = props

  const { balance } = useAuth()

  const [isModalFeaturesVisible, setIsModalFeaturesVisible] = useState(false)
  const showModalFeatures = () => setIsModalFeaturesVisible(true)
  const hideModalFeatures = () => setIsModalFeaturesVisible(false)

  const minStake = 0
  const maxStake = balance
  const [stakeAmount, setStakeAmount] = useState(0)

  const setStakeAmountInPercent = (percent) => {
    setStakeAmount((maxStake * percent) / 100)
  }

  const dailyRoi = apyAmount / 365
  const rewardPerDay = (dailyRoi / 100) * stakeAmount

  return (
    <>
      <Suspense fallback={<div />}>
        <ModalFeatures
          show={isModalFeaturesVisible}
          onHide={hideModalFeatures}
        />
      </Suspense>
      <div
        {...rest}
        className={classNames('card', style.cardStakeBlock, className)}
      >
        <div className={style.cardHeading}>
          <LogoWithCircleBackground width="24px" height="24px" />
          <h2 className="text-joyride-15">STAKE BLOCK</h2>
          <button
            type="button"
            onClick={showModalFeatures}
            className={style.btnPresent}
            title="features"
            aria-label="features"
          >
            <Icon name="icon-present" size={20} />
          </button>
        </div>
        <hr className={classNames('hr-card', style.hrHeading)} />
        <div className={style.inputWrapper}>
          <span
            className={classNames('text-syne-13-semi-bold', style.textBalance)}
          >
            Balance: {balance} BLOCK
          </span>
          <InputWithLabel
            placeholder={0}
            label="Amount to Stake"
            value={stakeAmount}
            onChange={(e) =>
              setStakeAmount(
                limitMaxValue(maskNumberValue(e.target.value), maxStake)
              )
            }
            autoComplete="off"
            inputMode="decimal"
          />
        </div>
        <div className={style.gridPercentButtons}>
          {STAKE_PERCENT_OPTIONS.map(({ percent, label }) => (
            <Button
              key={percent}
              variant="input-style"
              className="btn-white"
              onClick={() => setStakeAmountInPercent(percent)}
            >
              {label ?? `${percent}%`}
            </Button>
          ))}
        </div>
        <p className={classNames('text-joyride-15', style.textResult)}>
          {round(stakeAmount, 3)} BLOCK
        </p>
        <div className={style.inputRange}>
          <InputRange
            min={minStake}
            max={maxStake}
            step={0.01}
            values={[clamp(stakeAmount, minStake, maxStake)]}
            onChange={(values) => setStakeAmount(values[0])}
          />
        </div>
        <table className={style.table}>
          <tbody>
            <tr>
              <td>Reward per day</td>
              <td>
                <span>{round(rewardPerDay, 3)} BLOCK</span>
              </td>
            </tr>
            <tr>
              <td>DAILY ROI</td>
              <td>
                <span>{round(dailyRoi, 3)}%</span>
              </td>
            </tr>
            <tr>
              <td>APY</td>
              <td>
                <span>{apyAmount}%</span>
              </td>
            </tr>
          </tbody>
        </table>
        <Button variant="neon" className={style.btnStake}>
          Apply Stake
        </Button>
      </div>
    </>
  )
}

CardStakeBlock.defaultProps = {
  className: '',
}

CardStakeBlock.propTypes = {
  className: PropTypes.string,
  apyAmount: PropTypes.number.isRequired,
}

export default CardStakeBlock
