import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LogoWithCircleBackground from '~components/BlockassetLogo/assets/LogoWithCircleBackground'

import * as style from './BlockTokenPrice.module.scss'

const BlockTokenPrice = (props) => {
  const { className, ...rest } = props

  return (
    <div {...rest} className={classNames(style.blockTokenPrice, className)}>
      <LogoWithCircleBackground
        width="24px"
        height="24px"
        className={style.logo}
      />
      <span className={style.textLabel}>PRICE</span>
      <div className={classNames('text-joyride-13', style.blockPrice)}>
        <span className={style.textPrice}>$1.29</span>/BLOCK
      </div>
    </div>
  )
}

BlockTokenPrice.defaultProps = {
  className: '',
}

BlockTokenPrice.propTypes = {
  className: PropTypes.string,
}

export default BlockTokenPrice
