const RouteURL = {
  ASSET_HUB: '/',
  TOKEN_STAKING: '/',
  NFT_STAKING: '/nft-staking',
  NFT_STAKED: '/nft-staked',

  MEDIA: '/media',

  // Unused
  NFT: '/nft/',
  LEGEND: '/legend/',
  PROFILE: '/profile/',
}

export default RouteURL
