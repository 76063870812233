import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactPaginate from 'react-paginate'
import { useMediaQuery } from 'react-responsive'

import * as style from './Paginator.module.scss'

const Paginator = (props) => {
  const { className, pageCount, onPageChange, ...rest } = props

  const isBdMd = useMediaQuery({
    query: '(max-width: 640px)',
  })

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="Next"
      onPageChange={onPageChange}
      pageRangeDisplayed={isBdMd ? 4 : 5}
      marginPagesDisplayed={0}
      pageCount={pageCount}
      previousLabel="Back"
      renderOnZeroPageCount={null}
      className={classNames(style.paginator, className)}
      pageLinkClassName={style.pageLink}
      activeLinkClassName={style.activeLink}
      breakClassName={style.pageBreak}
      breakLinkClassName={style.pageLink}
      previousLinkClassName={classNames(
        'text-syne-14-bold',
        style.previousLink
      )}
      nextLinkClassName={classNames('text-syne-14-bold', style.nextLink)}
      disabledLinkClassName={style.disabledLink}
      {...rest}
    />
  )
}

Paginator.defaultProps = {
  className: '',
  onPageChange: () => {},
}

Paginator.propTypes = {
  className: PropTypes.string,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
}

export default Paginator
